import Header from '../header/Header';
import Footer from '../footer/Footer';

const highscore = () => {
    return (

        <div>
            <h1>Highscore</h1>
            <h1>Alltime</h1>
            <h1>Weekly</h1>
            <h1>Daily</h1>
            <Footer></Footer>
        </div>
    )
};

export default highscore;