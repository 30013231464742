import { Link, Outlet } from "react-router-dom";

import { useEffect } from 'react';
import './Header.css';

const Header = () => {
    useEffect(() => {
        document.title = "Fryk"
    }, []);
    return (
        <div>
            <div class="navbar">
                <a class="active" href="/">Home</a>
                <a href="/cv">Cv</a>

                <a href="/contact">Contact</a>
            </div>
            <div class="content">
                <main>
                    <Outlet />
                </main>
            </div>

        </div>
    )
};

export default Header;
/*

  <div>
            <div class="navbar">
                <a class="active" href="/">Home</a>
                <a href="/cv">Cv</a>
                <div class="dropdown">
                    <button class="dropbtn">Projects
                        <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="dropdown-content">
                        <a href="/projects/quiz/start">Quiz</a>
                        <a href="/projects/pokedex">Pokedex</a>
                        <a href="/projects/poker">Poker</a>
                    </div>
                </div>
                <a href="/contact">Contact</a>
            </div>
            <div class="content">
                <main>
                    <Outlet />
                </main>
            </div>

        </div>
*/