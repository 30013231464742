import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./Start.css"

function Start() {

    const [flipLiseberg, setFlipLiseberg] = useState("hidden liseberg");
    const [flipJohannes, setFlipJohannes] = useState("hidden johannes");
    const [flipOpenTrivia, setFlipOpenTrivia] = useState("hidden openTrivia");
    const [flipCategory, setFlipCategory] = useState("");

    return (
        <div>
            <h1>what quiz would you like to play?</h1>

            <button className={flipCategory} onClick={() => (setFlipLiseberg(""), setFlipCategory("hidden category"))}>Liseberg</button>
            <button className={flipCategory} onClick={() => (setFlipJohannes(""), setFlipCategory("hidden category"))}>Johannes</button>
            <button className={flipCategory} onClick={() => (setFlipOpenTrivia(""), setFlipCategory("hidden category"))}>Open Trivia</button>


            <button className={flipLiseberg} onClick={() => (setFlipLiseberg("hidden liseberg"), setFlipCategory(""))}>Back</button>
            <button className={flipLiseberg} ><Link to={"../quiz/liseberg/easy"}>Easy</Link></button>
            <button className={flipLiseberg}><Link to={"../quiz/liseberg/medium"}>Medium</Link></button>
            <button className={flipLiseberg}><Link to={"../quiz/liseberg/hard"}>Hard</Link></button>

            <button className={flipJohannes} onClick={() => (setFlipJohannes("hidden johannes"), setFlipCategory(""))}>Back</button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/liseberg"}>Liseberg</Link></button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/sälen"}>Sälen</Link></button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/lund"}>Lund</Link></button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/socionom"}>Socionom</Link></button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/scouterna"}>Scouterna</Link></button>
            <button className={flipJohannes}><Link to={"../quiz/johannes/blandat"}>Blandat</Link></button>

            <button className={flipOpenTrivia} onClick={() => (setFlipOpenTrivia("hidden openTrivia"), setFlipCategory(""))}>Back</button>
            <button className={flipOpenTrivia} ><Link to={"../quiz/openTrivia/film"}>Film</Link></button>
            <button className={flipOpenTrivia}><Link to={"../quiz/openTrivia/music"}>Music</Link></button>
            <button className={flipOpenTrivia}><Link to={"../quiz/openTrivia/sports"}>Sports</Link></button>            

        </div>
    )
};

export default Start;

// inte länk, den ska spara alternativet för att sedan skicka värdena som adresslänk.
// aka väljer man johannes + hard = så ska man gå vidare till ./johannes/hard
//
// <h5><Link to={"./Liseberg"}>Liseberg</Link></h5>
//    <h5><Link to={"./Johannes"}>Johannes</Link></h5>
//    <h5><Link to={"./easy"}>easy</Link></h5>
 //   <h5><Link to={"./medium"}>medium</Link></h5>
 //   <h5><Link to={"./hard"}>hard</Link></h5>


 // dom olika kategorierna ska inte vara hård kodade utan dom ska hämtas från backend.

 // behöver dela upp flip funktionen med ett löskopplat kategorinamn som kommer från backend.
 
 // quiz kan vara secret om man inte har tillgång och då inte synas för spelare.