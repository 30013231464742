import "./Welcome.css"
import Footer from '../footer/Footer';
import { Link } from "react-router-dom";


function Welcome() {
    return (
        <div>
            <h1 className='poker-text'> Unfortunatly the quiz site is under construction :/</h1>
        </div>
    )
};

export default Welcome;

/*
<div>
           <h1>Welcome to Johannes Quiz</h1>
           <h5><Link to={"../start"}>start</Link></h5>
           <h5><Link to={"../login"}>login</Link></h5>
           <h5><Link to={"../highscore"}>highscore</Link></h5>
           <h5><Link to={"../result"}>result</Link></h5>
           <h5><Link to={"../quiz/johannes/liseberg"}>quiz</Link></h5>
           <h5><Link to={"../about"}>about</Link></h5>
           <Footer></Footer>
       </div>
       */