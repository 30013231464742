import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Footer from '../footer/Footer';
import selfie from "./1477890_10151857174807903_1408959582_n.jpg"
import "./Contact.css"

function Contact() {



    return (
        <div className='body-css'>
            <div className='selfie-container'>
                <img className="selfie" src={selfie} alt="selfie of johannes"></img>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <p className='contact-text'>Ifall du är sugen på att komma i kontakt med mig är det bara att skriva på LinkedIn eller maila mig på fryk89@gmail.com </p>
            <br></br>
            <br></br>
            <br></br>

            <Footer />
        </div>
    )
};

export default Contact;