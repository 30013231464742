import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import { useEffect } from 'react';
import './App.css';


import Highscore from './components/projects/quiz/highscore/Highscore';
import Login from './components/projects/quiz/login/Login';
import MyOwnQuestions from './components/projects/quiz/myOwnQuestions/MyOwnQuestions';
import OpenTriviaQuestions from './components/projects/quiz/openTriviaQuestions/OpenTriviaQuestions';
import Result from './components/projects/quiz/result/Result';
import Cv from './components/cv/Cv';
import Contact from './components/contact/Contact';
import Start from './components/projects/quiz/start/Start';
import NotFound from './components/projects/quiz/notFound/NotFound';
import Header from './components/header/Header';
import HomePage from './components/homePage/HomePage';
import Pokedex from './components/projects/pokedex/Pokedex';
import Poker from './components/projects/poker/Poker';
import Welcome from './components/projects/quiz/welcome/Welcome';

function MyComponent() {

  return (
    <div>
      {/* Your component content */}
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(

    <Route path="/" element={<Header />}>
      <Route index element={<HomePage />} />
      <Route path="contact" element={<Contact />} />
      <Route path="cv" element={<Cv />} />
      <Route path="projects"> {/* ska jag ha den här eller ska projecten ligga direkt i? */}
        <Route path="pokedex" element={<Pokedex />} />
        <Route path="poker" element={<Poker />} />
        <Route path="quiz">
          <Route path="start" element={<Welcome />} />
          <Route path="result" element={<Result />} />
          <Route path="highscore" element={<Highscore />} />
          <Route path="login" element={<Login />} />

          <Route path="myownquestions/:id" element={<MyOwnQuestions />} />

          <Route path="opentriviaquestions/:id" element={<OpenTriviaQuestions />} />
          <Route path="start" element={<Start />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route >
  )
)

function App() {

  return (

    <RouterProvider router={router} />
  );
}

export default App;

/*
- params ska skickas för vilket quiz som väljs. 

*/