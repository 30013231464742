import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./Poker.css"

function Poker() {



    return (
        <div>
            <h1 className='poker-text'> Unfortunatly the poker site is under construction :/</h1>
        </div>
    )
};

export default Poker;