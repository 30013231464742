import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Footer from '../footer/Footer';
import "./Cv.css"

function Cv() {



    return (
        <div className='body-css'>
            <div className='cv-container'>
                <h1 className='cv-header'>CV</h1>
                <br></br>
                <h2 className='cv-header'>Arbetserfarenhet</h2>
                <br></br>
                <div className='cv-text'><div className='cv-text-left'>Operativ personalledare, SJ Trafikledning </div><div className='cv-text-right'>2021-</div></div>
                <div className='cv-text'><div className='cv-text-left'>Attraktionsförare/Utbildningsledare/Poolare/Operatör, Liseberg  </div><div className='cv-text-right'>2015-</div></div>
                <div className='cv-text'><div className='cv-text-left'>Systemutvecklare,Fiwe  </div><div className='cv-text-right'>2023</div></div>
                <div className='cv-text'><div className='cv-text-left'>HR / IT konsult, Academic Work  </div><div className='cv-text-right'>2022-2023</div></div>
                <div className='cv-text'><div className='cv-text-left'>Rekryteringsassistent, TNG Group AB  </div><div className='cv-text-right'>2019-2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Butiksbiträde, Pressbyrån  </div><div className='cv-text-right'>2018</div></div>
                <div className='cv-text'><div className='cv-text-left'>Butiksbiträde, HM  </div><div className='cv-text-right'>2017</div></div>
                <div className='cv-text'><div className='cv-text-left'>Butiksbiträde Skistarshop, Skistar  </div><div className='cv-text-right'>2016-2017</div></div>
                <div className='cv-text'><div className='cv-text-left'>Montör, Volvo  </div><div className='cv-text-right'>2015-2016</div></div>
                <div className='cv-text'><div className='cv-text-left'>Attraktionsförare/Utbildningsledare, Liseberg  </div><div className='cv-text-right'>2014</div></div>
                <div className='cv-text'><div className='cv-text-left'>Attraktionsförare, Liseberg  </div><div className='cv-text-right'>2011-2013</div></div>
                <div className='cv-text'><div className='cv-text-left'>Restaurangbiträde, Burger King  </div><div className='cv-text-right'>2011</div></div>
                <div className='cv-text'><div className='cv-text-left'>Restaurangbiträde, Pizza Hut  </div><div className='cv-text-right'>2010-2011</div></div>
                <div className='cv-text'><div className='cv-text-left'>Funktionär, Arvikafestivalen / Dreamhack  </div><div className='cv-text-right'>2010</div></div>
                <div className='cv-text'><div className='cv-text-left'>Kolloledare, Scouterna </div><div className='cv-text-right'>2009 - 2011</div></div>

                <h2 className='cv-header'>Utbildning</h2>
                <br></br>
                <div className='cv-text'><div className='cv-text-left'>Java programet, AW Academy </div><div className='cv-text-right'>2022</div></div>
                <div className='cv-text'><div className='cv-text-left'>Personalvetarprogrammet, Lunds Universitet 180hp</div><div className='cv-text-right'>2018-2021
                </div></div>
                <div className='cv-text'><div className='cv-text-left'>datasäkerhet Högskolan Kristianstad 7.5hp </div><div className='cv-text-right'>2021</div></div>
                <div className='cv-text'><div className='cv-text-left'>Java programmering Uppsala Universitet 7.5hp </div><div className='cv-text-right'>2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Webbdesign Linnéuniversitetet 7.5hp </div><div className='cv-text-right'>2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Ekonomisk Historia, Göteborgs Universitet 7.5hp </div><div className='cv-text-right'>2016</div></div>
                <div className='cv-text'><div className='cv-text-left'>Marknadsföring, Göteborgs Universitet 7.5hp</div><div className='cv-text-right'>2015</div></div>
                <div className='cv-text'><div className='cv-text-left'>Socialt Arbete, Göteborgs Universitet 60hp</div><div className='cv-text-right'>2012-2013</div></div>
                <div className='cv-text'><div className='cv-text-left'>Naturprogrammet, Burgårdens Gymnasium  </div><div className='cv-text-right'>2005-2008</div></div>
                <h2 className='cv-header'>Föreningsliv</h2>
                <br></br>
                <div className='cv-text'><div className='cv-text-left'>Fotboll, Majornas Fotklan </div><div className='cv-text-right'>2010-2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Valberedningsordförande, Västgöta Nation </div><div className='cv-text-right'>2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Metrobarförman, Västgöta Nation </div><div className='cv-text-right'>2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Novischansvarig, Personalvetare i Lund ( PIL) </div><div className='cv-text-right'>2019-2020</div></div>
                <div className='cv-text'><div className='cv-text-left'>Fadder, Socionomutbildningen  </div><div className='cv-text-right'>2012-2013</div></div>
                <div className='cv-text'><div className='cv-text-left'>Socionomernas Utbildningsråd, SUR & SOL </div><div className='cv-text-right'>2012-2013</div></div>
                <div className='cv-text'><div className='cv-text-left'>Barjobbare, Festförening Byrå6, Göteborgs Universitet   </div><div className='cv-text-right'>2012</div></div>
                <div className='cv-text'><div className='cv-text-left'>Ledare, Backa Scoutkår  </div><div className='cv-text-right'>2010</div></div>


                <h2 className='cv-header'>Övrigt</h2>
                <div className='cv-text'><div className='cv-text-left'>Körkort B </div><div className='cv-text-right'>2019</div></div>
                <div className='cv-text'> <div className='cv-text-left'>Flytande Svenska och Engelska</div></div>
                <br></br>
                <br></br>

            </div>
            <Footer />
        </div>
    )
};

export default Cv;