import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Footer from '../footer/Footer';
import "./HomePage.css"
import selfie from "./_MG_3367.jpg"

function HomePage() {



    return (
        <div >
            <div className='body-css'>
                <h1 className='header-text'>Johannes Fryk</h1>
                <div className='selfie-container'>
                    <img className="selfie" src={selfie} alt="selfie of johannes"></img>
                </div>
                <p className='selfie-text'>
                    <br></br>
                    <br></br>
                    Johannes Fryk är en junior HR- och IT-specialist. Med en mångsidig bakgrund och en mängd olika erfarenheter.
                    Med en kandidatexamen inom Personal och arbetslivsfrågor från Lunds Universitet i grunden så har han hunnit jobba inom områden som personalplanering,
                    rekrytering och arbetsledning, vilket har gett honom värdefulla insikter i mänskliga resurser och organisationshantering.
                    <br></br>
                    <br></br>
                    Under de senaste åren har han även tagit stegen in i teknikens värld och fokuserat på mjukvaruutveckling.
                    Efter att ha jobbat lite med IT-rekrytering och fått upp ögonen för IT-området så började han först läsa lite IT-kurser på universitet för att lära sig lite mer inom området.
                    Men då detta bara gjorde att Johannes fick mersmak för IT valde han senare att gå en intensivkurs hos Academic Work i Javautveckling.
                    Efter att han gått färdigt utbildningen har han jobbat med Java, Node.js och PHP.
                    Han har trivts med att lösa tekniska utmaningar och skapa applikationer som förbättrar användarupplevelsen.

                    <br></br>
                    <br></br>
                    Med tidigare erfarenheter från servicebranschen där han utvecklade starka kommunikationsfärdigheter och samarbetsfärdigheter.
                    Samt senare HR- och IT-kompetenser, blir han ett utmärkt tillskott till ditt team.
                    Oavsett om du är i behov av en utvecklare, produktägare, rekryterare eller något annat inom HR eller IT-sektorn.
                    Han är passionerad och öppen för att fortsätta utforska och utvecklas inom både HR och IT så ifall du är i behov av kompetent personal så tveka inte att höra av dig.


                    <br></br>
                    <br></br>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </p>

                <Footer />
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
};

export default HomePage;


/*

titta på om man kan lösa en bild med transparant bakgrund så man kan 

*/