import React, { useState } from 'react';
import { Link } from "react-router-dom";

import "./Pokedex.css"

function Pokedex() {



    return (
        <div>
            <h1 className='pokedex-text'> Unfortunatly the Pokedex site is under construction :/</h1>
        </div>
    )
};

export default Pokedex;