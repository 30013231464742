import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";


function MyOwnQuestions() {

    const { id } = useParams();
    // lägga in paramen för att man ska veta vilken hemsida som ska fetchas från backenden. 
    const { isLoading, data, error } = useFetch("http://localhost:8080/question");

    //const { isLoading, data, error } = useFetch("https://opentdb.com/api.php?amount=10&type=multiple");

    console.log(data)

    const [count, setCount] = useState(1);
    const [done, setDone] = useState(true);

    const CheckCount = () => {

        if (count < 10) {
            setCount(count + 1)
        }
        if (count == 10) {
            setDone(false)
        }
    }
    if (error) {
        return (
            <h2>I'm sorry there seems to be a problem getting the question.... :/</h2>)
    }

    return (

        <div>
            {done ?
                (<div>
                    {isLoading ? <h2>Loading...</h2> : <div> <h2>{data.question}</h2>
                        <li><button onClick={() => CheckCount()}>{data.alternative1}</button></li>
                        <li><button onClick={() => CheckCount()}>{data.alternative2}</button></li>
                        <li><button onClick={() => CheckCount()}>{data.alternative3}</button></li>
                        <li><button onClick={() => CheckCount()}>{data.alternative4}</button></li></div>}
                </div>)
                : (
                    <div>
                        <h1>Bra jobbat!</h1>
                        <button><Link to={"../result"}>visa result</Link></button>
                    </div>
                )}


        </div>
    )
};

export default MyOwnQuestions;

// poäng ska räknas för varje rätt.