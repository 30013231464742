import linkedin from "./icons8-linkedin.svg"
import facebook from "./icons8-facebook.svg"
import instagram from "./icons8-instagram.svg"

import "./Footer.css"

const footer = () => {
    return (
        <div className="footer">
            <a href="https://www.linkedin.com/in/johannes-fryk-54a4b9173/"><img className="linkedin" src={linkedin} alt="linkedin logo"></img></a>
            <a href="https://www.facebook.com/johannes.fryk"><img className="facebook" src={facebook} alt="facebook logo"></img></a>
            <a href="https://www.instagram.com/johannesfryk/"><img className="instagram" src={instagram} alt="instagram logo"></img></a>
            <div>© 2023 Johannes Fryk. All rights reserved.</div>
        </div>
    )
};

export default footer;