import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Result() {
    const [count, setCount] = useState(1);


    return (
        <div>
            <h1>Ditt resultat är.....{count}</h1>
            <Link to={"../"}><button>Hem</button></Link>
            <Link to={"../start"}><button>Spela igen</button></Link>
            <Link to={"../highscore"}><button>Visa highscore</button></Link>
        </div>

    )
};

export default Result;

// visa antal rätt man fått från sitt quiz.