import Header from '../header/Header';
import Footer from '../footer/Footer';

const login = () => {
    return (
        <div>

            <h1>Here you can log in</h1>
            <form>
                <label>
                    användarnamn
                    <input type="text" name="username"></input>
                </label>
                <label>
                    lösenord
                    <input type="password" name="password"></input>
                </label>
                <input type="submit" value="logga in"></input>
            </form>

            <Footer></Footer>
        </div>


    )
};

export default login;

// lägga in backend koll på ifall användaren finns och isåfall logga in